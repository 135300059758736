@-webkit-keyframes bounce {
  0% {
    bottom: 1em;
  }
  25%, 75% {
    bottom: 1.2em;
  }
  50% {
    bottom: 1.5em;
  }
  100% {
    bottom: 1em;
  }
}